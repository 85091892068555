import React from 'react';
import { Helmet } from 'react-helmet';

import '../scss/style.scss';

import Header from '../components/Header';
import OurWork from '../components/OurWork';
import Services from '../components/Services';
import Contact from '../components/Contact';

export default class Index extends React.PureComponent {
	render() {
		return (
			<>
				<Helmet>
					<title>Coinage | The Blockchain Experts</title>
				</Helmet>

				<Header />

				<div className="page main-page">
					<Services />
					<OurWork />
					<Contact />
				</div>

				<footer>
					<div className="copyright">
						&copy; {new Date().getFullYear()} Coinage. All Rights Reserved
					</div>
				</footer>
			</>
		);
	}
}
