import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { graphql, StaticQuery } from 'gatsby';

export default class Header extends React.PureComponent {
	state = {
		menuOpen: false,
	};

	toContact = e => {
		e.preventDefault();

		scroll.scrollTo(document.getElementById('contact').offsetTop);

		this.setState({ menuOpen: false });
	};

	toService = e => {
		e.preventDefault();

		scroll.scrollTo(document.getElementById('services').offsetTop);

		this.setState({ menuOpen: false });
	};

	toWork = e => {
		e.preventDefault();

		scroll.scrollTo(document.getElementById('our-works').offsetTop);

		this.setState({ menuOpen: false });
	};

	toggleMenu = () => {
		this.setState({ menuOpen: !this.state.menuOpen });
	};

	getData(data) {
		return data.allPagesJson.edges[0].node;
	}

	render() {
		return (
			<StaticQuery
				query={graphql`
					query HeaderQuery {
						allPagesJson(filter: { id: { eq: "index" } }) {
							edges {
								node {
									hero_slogan
									services_cta_button_text
									buttons {
										button_text
										button_url
									}
								}
							}
						}
					}
				`}
				render={data => (
					<header>
						<div className="wrap-menu">
							<div className="container d-flex align-items-center justify-content-between">
								<a href="#" className="logo">
									<img src="img/logo.svg" alt="Coinage" />
								</a>
								<a tabIndex="0" aria-hidden="true" className="menu-icon" onClick={this.toggleMenu}>
									Open Menu
								</a>
								<span tabIndex="-1" aria-hidden="true" className="d-none"></span>
								<nav role="navigation" className={`navigation ${this.state.menuOpen && 'open-js'}`}>
									<a
										href="#services"
										onClick={this.toService}
										className="d-md-inline-block btn-link anchor-link-js"
									>
										Services
									</a>
									<a
										href="#our-works"
										onClick={this.toWork}
										className="d-md-inline-block btn-link anchor-link-js"
									>
										Our Work
									</a>
									<a
										href="#contact"
										onClick={this.toContact}
										className="d-md-inline-block btn-outline anchor-link-js"
									>
										Contact
									</a>
								</nav>
							</div>
						</div>
						<div>
							<p
								className="header-title"
								dangerouslySetInnerHTML={{ __html: this.getData(data).hero_slogan }}
							></p>
							<a
								href="#contact"
								onClick={this.toContact}
								className="btn-prim btn-heder anchor-link-js"
							>
								{this.getData(data).services_cta_button_text}
							</a>
						</div>
					</header>
				)}
			/>
		);
	}
}
