import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

export default class OurWorks extends React.PureComponent {
	getData(data) {
		return data.allPagesJson.edges[0].node;
	}

	render() {
		return (
			<StaticQuery
				query={graphql`
					query OurWorkQuery {
						allPagesJson(filter: { id: { eq: "index" } }) {
							edges {
								node {
									our_work_heading
									our_works {
										heading
										year
										work1
										work2
										url
										platform
										text
									}
								}
							}
						}
					}
				`}
				render={data => {
					const { our_work_heading, our_works } = data.allPagesJson.edges[0].node;

					return (
						<div id="our-works">
							<h2 className="section-title">{our_work_heading}</h2>
							<div className="wrap-container position-relative">
								<div className="container" id="synthetix">
									<div className="row">
										<div className="col col-12 col-md-6 col-lg-5 order-md-12">
											<img
												src="img/logo-1.svg"
												alt=""
												width="29"
												height="38"
												className="works-logo"
											/>
											<h4 className="section-subtitle">{our_works[0].heading}</h4>
											<ul className="works-list">
												<li className="works-list-item">
													<i className="icon year-icon"></i>
													{our_works[0].year}
												</li>
												<li
													className="works-list-item"
													dangerouslySetInnerHTML={{
														__html: our_works[0].work1,
													}}
												></li>
												<li
													className="works-list-item"
													dangerouslySetInnerHTML={{
														__html: our_works[0].work2,
													}}
												></li>
												<li className="works-list-item">
													<i className="icon url-icon"></i>
													{our_works[0].url}
												</li>
												<li className="works-list-item">
													<i className="icon platform-icon"></i>
													{our_works[0].platform}
												</li>
											</ul>
											<p className="text">{our_works[0].text}</p>
										</div>
										<div className="col col-12 col-md-6 col-lg-7 order-md-1 bg-synthetix position-static">
											<picture>
												<source srcSet="img/bg-synthetix-lg.png" media="(min-width: 992px)" />
												<img src="img/bg-synthetix.png" alt="" />
											</picture>
										</div>
									</div>
								</div>
							</div>
							<div className="wrap-container position-relative">
								<div className="container" id="global">
									<div className="row">
										<div className="col col-12 col-md-6 col-lg-5">
											<img
												src="img/logo-global.svg"
												alt=""
												width="42"
												height="38"
												className="works-logo"
											/>
											<h4 className="section-subtitle">{our_works[1].heading}</h4>
											<ul className="works-list">
												<li className="works-list-item">
													<i className="icon year-icon"></i>
													{our_works[1].year}
												</li>
												<li
													className="works-list-item"
													dangerouslySetInnerHTML={{
														__html: our_works[1].work1,
													}}
												></li>
												<li
													className="works-list-item"
													dangerouslySetInnerHTML={{
														__html: our_works[1].work2,
													}}
												></li>
												<li className="works-list-item">
													<i className="icon url-icon"></i>
													{our_works[1].url}
												</li>
												<li className="works-list-item">
													<i className="icon platform-icon"></i>
													{our_works[1].platform}
												</li>
											</ul>
											<p className="text">{our_works[1].text}</p>
										</div>
										<div className="col col-12 col-md-6 col-lg-7 bg-global position-static">
											<picture>
												<source srcSet="img/bg-global-lg.png" media="(min-width: 992px)" />
												<img src="img/bg-global.png" alt="" />
											</picture>
										</div>
									</div>
								</div>
							</div>
							<div className="wrap-container position-relative">
								<div className="container" id="enome">
									<div className="row">
										<div className="col col-12 col-md-6 col-lg-5 order-md-12">
											<img
												src="img/logo-enome.svg"
												alt=""
												width="139"
												height="32"
												className="works-logo"
											/>
											<h4 className="section-subtitle">{our_works[2].heading}</h4>
											<ul className="works-list">
												<li className="works-list-item">
													<i className="icon year-icon"></i>
													{our_works[2].year}
												</li>
												<li
													className="works-list-item"
													dangerouslySetInnerHTML={{
														__html: our_works[2].work1,
													}}
												></li>
												<li
													className="works-list-item"
													dangerouslySetInnerHTML={{
														__html: our_works[2].work2,
													}}
												></li>
												<li className="works-list-item">
													<i className="icon url-icon"></i>
													{our_works[2].url}
												</li>
												<li className="works-list-item">
													<i className="icon platform-icon"></i>
													{our_works[2].platform}
												</li>
											</ul>
											<p className="text">{our_works[2].text}</p>
										</div>
										<div className="col col-12 col-md-6 col-lg-7 order-md-1 bg-enome position-static">
											<picture>
												<source srcSet="img/bg-enome-lg.png" media="(min-width: 992px)" />
												<img src="img/bg-enome.png" alt="" />
											</picture>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				}}
			/>
		);
	}
}
