import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

export default () => (
	<StaticQuery
		query={graphql`
			query ServicesQuery {
				allPagesJson(filter: { id: { eq: "index" } }) {
					edges {
						node {
							services {
								heading
								image
								text
							}
						}
					}
				}
			}
		`}
		render={data => {
			const { services } = data.allPagesJson.edges[0].node;

			return (
				<div id="services" className="container">
					<div className="row">
						{services.map((service, index) => (
							<div key={service.heading} className="col-12 col-md-4">
								<div className="card">
									<div className="wrap-icon">
										<img src={service.image} alt="" className={`services-icon-${index + 1}`} />
									</div>
									<div className="card-title">{service.heading}</div>
									<p
										className="card-descr px-3"
										dangerouslySetInnerHTML={{ __html: service.text }}
									></p>
								</div>
							</div>
						))}
					</div>
				</div>
			);
		}}
	/>
);
