import React from 'react';
import ReCAPTCHA from "react-google-recaptcha"
import { configureForm } from '@thinkmill/pragmatic-forms';

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.recaptchaRef = React.createRef();
        this.state = { hasSubmitted: false, buttonDisabled: true };
    }

	encode(data) {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
			.join('&');
	}

	ContactForm = configureForm({
		initFields: () => ({
			name: '',
			email: '',
			company: '',
			budget: '<40k',
			inquiry: '',
		}),
		validate: ({ name, email, budget, inquiry }) => {
			const errors = {};

			if (!name) errors.name = 'Please enter your Name';
			if (!email) errors.email = 'Please enter an Email Address';
			if (!budget) errors.budget = 'Please enter a Budget';
			if (!inquiry) errors.inquiry = 'Please enter an Inquiry';

			const errorList = Object.values(errors);
			if (errorList.length > 0) {
				alert(errorList.join('\n'));
			}
			return errors;
		},
		submit: async formData => {
			try {
                const recaptchaValue = this.recaptchaRef.current.getValue()

				await fetch('/', {
					method: 'POST',
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
					body: this.encode({ 
                        'form-name': 'email-form', 
                        'g-recaptcha-response': recaptchaValue,
                        ...formData 
                    }),
				});

				this.setState({ hasSubmitted: true });
			} catch (error) {
				console.error(error);
			}
		},
	})(({ form }) => {
        //const pageSettings = this.props.data.page.edges[0].node;
        const contatcForm = <>
                                <div className="row mrgt">
                                    <div className="col-12 col-md-6">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="icon name-icon"></i>
                                                            </span>
                                            </div>
                                            <input
                                                type="text"
                                                id="txtName"
                                                name="txtName"
                                                maxLength="256"
                                                placeholder="Name"
                                                required
                                                className="form-control"
                                                {...form.getInputProps({ name: 'name', type: 'text' })}
                                            />
                                        </div>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="icon email-icon"></i>
                                                            </span>
                                            </div>
                                            <input
                                                type="email"
                                                name="Email"
                                                maxLength="256"
                                                placeholder="Email"
                                                required
                                                className="form-control"
                                                {...form.getInputProps({ name: 'email', type: 'email' })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="icon company-icon"></i>
                                                            </span>
                                            </div>
                                            <input
                                                type="text"
                                                id="txtCompany"
                                                name="txtCompany"
                                                maxLength="256"
                                                placeholder="Company"
                                                className="form-control"
                                                {...form.getInputProps({ name: 'company', type: 'text' })}
                                            />
                                        </div>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="icon budget-icon"></i>
                                                            </span>
                                            </div>
                                            <select
                                                className="form-control"
                                                id="txtBudget"
                                                name="txtBudget"
                                                {...form.getInputProps({ name: 'budget', type: 'text' })}
                                            >
                                                <option value="0" disabled>
                                                    Budget
                                                </option>
                                                <option value="<40k">&lt; 40k</option>
                                                <option value="40k-80k">40k-80k</option>
                                                <option value="80k-150k">80k-150k</option>
                                                <option value="150k+">150k+</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="input-group textarea-group">
                                            <div className="input-group-prepend">
                                                            <span className="input-group-text align-items-start">
                                                                <i className="icon message-icon"></i>
                                                            </span>
                                            </div>
                                            <textarea
                                                id="txtMessage"
                                                name="txtMessage"
                                                className="form-control"
                                                rows="2"
                                                maxLength="5000"
                                                placeholder="Message"
                                                required
                                                spellCheck="false"
                                                {...form.getInputProps({ name: 'inquiry', type: 'text' })}
                                            ></textarea>
                                        </div>
                                        <ReCAPTCHA
                                            ref={this.recaptchaRef}
                                            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                                            style={{ display: "inline-block" }}
                                            size="normal"
                                            id="recaptcha-google"
                                            onChange={() => this.setState({ buttonDisabled: false })}
                                        />
                                        <button className="btn btn-prim btn-submit" disabled={this.state.buttonDisabled}>Submit</button>
                                    </div>
                                </div>
                            </>;

        const thanksMessage = <div className="message-container">
                                <p className="thanks-message">Thank you for your message. We will be in touch soon.</p>
                              </div>;

        const contactData = !this.state.hasSubmitted ? contatcForm : thanksMessage;

		return (
			<form.Form netlify="true" id="email-form" name="email-form" data-netlify-recaptcha="true">
				<div className="row justify-content-md-center bg-contact">
					<div className="col-12 col-md-4 col-lg-3">
						<h2 className="section-title">Contact Us</h2>
						<p className="get">
							Get in touch with the team at Coinage
						</p>
					</div>
					<div className="col-12 col-md-8 col-lg-7">
                        {contactData}
					</div>
				</div>
			</form.Form>
		);
	});

	render() {
		return (
			<div id="contact">
				<div className="container">
                    <this.ContactForm/>
				</div>
			</div>
		);
	}
}
